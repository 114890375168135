import {JSX, useEffect, useState} from "react";

const projects: {id : string, title : string, img: string, description: string, technologies: {name: string, img: string}[]}[]= [
    {
        id: '1',
        title: 'Comité des Fêtes',
        img: 'images/cdf.png',
        description: 'An application created with React Native and Firebase, designed for an association, to browse and manage reservations, equipment and dutys.',
        technologies: [{name:'React Native', img:'react-native.png'}, {name:'Javascript', img:'js.png'}, {name:'Firebase', img:'firebase.png'}]
    },
    {
        id: '2',
        title: 'Loup-Garou',
        img: 'images/Fearview.jpg',
        description: 'The famous Werewolf game, in a mobile version, with new roles. Created with Unity and a Java server.',
        technologies: [{name:'Unity', img:'unity.svg'}, {name:'C#', img:'csharp.png'}, {name:'Java', img:'java.png'}]
    },
    {
        id: '3',
        title: 'Hnefatafl',
        img: 'images/hnefatafl.png',
        description: 'A console version of the Viking game "Hnefatafl", created with C++.',
        technologies: [{name:'C++', img:'cpp.png'}]
    },
    {
        id: '4',
        title: 'Star Clash',
        img: 'images/StarClash.jpg',
        description: 'A platform fighting game created with the Qt framework in C++. The game is 1v1 locally. Developed in a team of 4 with git.',
        technologies: [{name:'Qt', img:'qt.png'}, {name:'C++', img:'cpp.png'}, {name:'Git', img:'git.png'}]
    },
    {
        id: '5',
        title: 'Smart Campus',
        img: 'images/SC.png',
        description: 'A data acquisition project in rooms using sensors and an ESP32. With a dashboard website developed with Symfony in PHP displaying data and information. Developed in a team of 5 with GIT using the SCRUM methodology.',
        technologies: [{name:'PHP', img:'php.png'}, {name:'Symfony', img:'symfony.png'}, {name:'ESP32', img:'esp32.png'}, {name:'Git', img:'git.png'}]
    },
];

const Projects = ({isPortrait} : {isPortrait : boolean}) => {
    const [index, setIndex] = useState(0 as number);
    const [elements, setElements] = useState([] as JSX.Element[]);
    const [translateX, setTranslateX] = useState(0 as number);
    const [translateY, setTranslateY] = useState(0 as number);
    const DELAY = 5600;

    useEffect(() => {
        setElements(projects.map((project) => {
            return <Project key={project.id} img={project.img} title={project.title} description={project.description} technologies={project.technologies}/>;
        }));
    }, []);

    useEffect(() => {
        setTranslateX(-index*100)
        setTranslateY(-index*(1/projects.length*100))
    }, [index]);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((index + 1) % elements.length);
        }, DELAY);

        return () => clearInterval(interval);
    }, [elements.length, index])

    return (
        <section style={styles.btnContainer}> {/* Flex Container */}
            <div style={styles.carousel}> {/* Fix Container */}
                <div style={{...styles.counter, left: isPortrait ? '-25px' : '20px', top: '10px', transform: `translateY(-${(100/(projects.length+1))*projects.length}%)`}}>
                    {projects.map((project, i) => {
                        return <h2 key={project.id} style={{
                            color: i === index ? '#E06351' : '#373344',
                            opacity: i === index ? '1' : '0.2',
                            transition: 'all 0.5s ease',
                            margin: 0,
                            transform: `translateY(${index*100}%)`
                        }}>{project.id}</h2>
                    }).reverse()}
                </div>

                <div style={{
                    width: `${elements.length * 100}%`,
                    transform: `translateX(${translateX}vw)`,
                    transition: 'transform 0.5s ease'
                }}> {/* Fix Container */}
                    <div style={styles.flexCardContainer}> {/* Flex Container */}
                        {elements}
                    </div>
                </div>

                <div style={{...styles.projectTitles, top: isPortrait ? '10px' : '30px', transform: `translateY(${translateY}%)`,}}>
                    {projects.map((project, i) => {
                        return <h3 key={i} style={{
                            ...styles.projectTitle,
                            opacity: i === index ? 1 : 0,
                            transform: i === index ? i % 2 === 0 ? `rotateZ(-2deg)` : `rotateZ(2deg)` : 'rotateZ(0deg)',
                        }}><span style={{backgroundColor: '#E06351'}}>{project.title}</span></h3>
                    })}
                </div>
            </div>
            <div style={styles.dots}>
                {elements.map((element, i) => {
                    return <button style={{
                        ...styles.dot,
                        width: i === index ? '60px' : '12px',
                        height: i === index ? '16px' : '12px',
                        backgroundColor: i === index ? '#E06351' : '#373344',
                        border: i === index ? '2px solid #373344' : 'none',
                        padding: i === index ? '3px' : '0',
                    }} key={i} onClick={() => setIndex(i)}></button>
                })}
            </div>
        </section>
    );
}

const Project = ({img, title, description, technologies}: {img: string, title: string, description: string, technologies : {name : string, img : string}[] }) => {
    const [hover, setHover] = useState(false);

    return (
        <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} style={{...styles.projectBack, scale: hover ? '1.01' : '1'}}>
            <img src={img} alt={title} style={styles.projectImg}/>
            <div style={{...styles.projectContent, opacity: hover ? 1 : 0, background: hover ? 'linear-gradient(0deg, rgba(55,51,68,1) 0%, rgba(255,255,255,0) 100%)' : 'rgba(255,255,255,0)'}}>
                <div style={styles.technologyContainer}>
                    {technologies.map((technology, i) => {
                        return <img src={`images/${technology.img}`} alt={technology.name} style={{height: '50px'}} key={i}/>
                    })}
                </div>
                <p style={{paddingLeft: '10px', paddingRight: '10px'}}>{description}</p>
            </div>
        </div>
    );
}

const styles = {
    btnContainer: {
        backgroundColor: '#F2EDDA',
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative' as 'relative',
    }, dots: {
        display: 'flex',
        justifyContent: 'center' as 'center',
        alignItems: 'center' as 'center',
        gap: '20px',
        marginTop: '10px',
        marginBottom: '20px',
        height: '20px',
    }, dot: {
        borderRadius: '6px',
        boxSizing: 'border-box' as 'border-box',
        backgroundClip: 'content-box' as 'content-box',
        cursor: 'pointer',
        transition: 'all 0.5s ease'
    }, carousel: {
        position: 'relative' as 'relative',
        width: '100vw',
        overflow: 'hidden' as 'hidden'
    }, flexCardContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row' as 'row',
        transition: 'transform 0.5s ease-in-out',
    }, cardContainer: {
        width: '200%',
    }, projectBack: {
        position: 'relative' as 'relative',
        margin: '4vw 13vw 1vw 13vw',
        borderRadius: '10px',
        boxShadow: '3px 3px 8px #000000',
        width: '25%',
        display: 'flex',
        flexDirection: 'column' as 'column',
        transition: 'all 0.5s ease',
    }, projectImg: {
        flexGrow: 1,
        objectFit: 'cover' as 'cover',
        width: '100%',
        height: '650px',
        borderRadius: '10px',
    }, counter: {
        position: 'absolute' as 'absolute',
        fontFamily: 'Poppins, serif',
        fontWeight: 900,
        fontSize: '4.5rem',
        padding: '0'
    }, projectTitles: {
        position: 'absolute' as 'absolute',
        width: '100%',
        transition: 'transform 0.5s ease',
    }, projectTitle: {
        color: '#ffffff',
        transition: 'all 0.5s ease',
        fontSize: '3.5rem',
        textShadow: '2px 2px 4px #000000',
        fontFamily: 'Victoria, serif',
        margin: 0,
    }, projectContent: {
        color: '#ffffff',
        paddingBottom: '10px',
        transition: 'all 0.5s ease',
        borderRadius: '0 0 10px 10px',
        position: 'absolute' as 'absolute',
        bottom: 0,
        width: '100%',
        fontFamily: 'Poppins, serif',
        fontWeight: 200,
        fontSize: '1.2rem',
        textAlign: 'center' as 'center',
        marginBottom: '0',
    }, title: {
        fontFamily: 'Victoria, serif',
        fontSize: '2.5vw',
        textAlign: 'center' as 'center',
        margin: 0,
        marginTop: '30px',
    }, technologyContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '30px',
        marginTop: '10px',
    }
}

export default Projects;